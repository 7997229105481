var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%",
      "margin-top": "70px"
    }
  }, [_c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "to": {
        name: 'e-book'
      }
    }
  }, [_vm._v("E-book")]), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v("Paling Banyak Dicari")])], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "sm": "12",
      "md": "8",
      "xl": "10"
    }
  }, [_c('h6', {
    staticClass: "mb-1 text-left"
  }, [_c('b', [_vm._v("Paling Banyak Dicari")])]), _c('b-form-group', {
    staticClass: "blog-search mt-2"
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "search-input",
      "placeholder": "Search here"
    },
    model: {
      value: _vm.q,
      callback: function callback($$v) {
        _vm.q = $$v;
      },
      expression: "q"
    }
  }), _c('b-input-group-append', {
    staticClass: "cursor-pointer",
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.data[0] == null ? _c('div', {
    staticClass: "text-center mt-5 mb-5"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/elp/empty.png'),
      "fluid": "",
      "width": "200px"
    }
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("E-book tidak ditemukan!!")])], 1) : _c('b-row', {
    staticClass: "blog-list-wrapper"
  }, _vm._l(_vm.currentPageItems, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('b-card', {
      staticClass: "text-left text-dark",
      staticStyle: {
        "height": "350px"
      },
      attrs: {
        "tag": "article",
        "no-body": ""
      }
    }, [_c('b-container', {
      staticClass: "bg-light-secondary",
      staticStyle: {
        "text-align": "center",
        "padding": "10px"
      },
      attrs: {
        "fluid": ""
      }
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "card-img-top",
      staticStyle: {
        "height": "150px",
        "object-fit": "cover",
        "width": "100%"
      },
      attrs: {
        "src": _vm.apiFile + item.photo,
        "alt": "".concat(item.name, "-").concat(item.id)
      }
    })], 1)], 1), _c('b-card-body', [_c('b-card-title', {
      staticStyle: {
        "font-size": "0.75rem"
      }
    }, [_c('b-link', [_c('b-badge', {
      staticClass: "mr-75",
      attrs: {
        "pill": "",
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(item.category.name) + " ")])], 1)], 1), _c('b-card-text', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top.v-primary",
        value: item.name,
        expression: "item.name",
        modifiers: {
          "hover": true,
          "top": true,
          "v-primary": true
        }
      }, {
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "item-content-truncate",
      staticStyle: {
        "max-height": "50px",
        "overflow": "auto"
      },
      attrs: {
        "title": "tooltip on top",
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('hr'), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [item.price > 0 ? _c('b-link', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.formatRupiah(item.price)) + " ")]) : _c('b-link', {
      staticClass: "font-weight-bold",
      attrs: {
        "to": {
          name: 'e-book-detail',
          params: {
            id: item.slug
          }
        }
      }
    }, [_vm._v(" Gratis ")])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalData,
      "per-page": _vm.filters.perPage,
      "first-number": "",
      "align": "center",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "page", $$v);
      },
      expression: "filters.page"
    }
  })], 1)], 1)], 1)])], 1), _c('footer-home')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }